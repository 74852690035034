<template>
  <div>
    <SettingTable
      header="Status"
      v-model:items="statuses"
      @addItem="addStatus"
      @deleteItem="deleteStatus"
      @onRowReorder="onStatusRowReorder"
      @onRowUpdate="onStatusRowUpdate"
    />

    <SettingTable
      header="Category"
      v-model:items="categories"
      @addItem="addCategory"
      @deleteItem="deleteCategory"
      @onRowReorder="onCategoryRowReorder"
      @onRowUpdate="onCategoryRowUpdate"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import SettingTable from "@/components/SettingTable";

import NicheAnalysisService from "@/services/niche_analysis_service";

export default defineComponent({
  components: {
    SettingTable,
  },

  setup() {
    onMounted(() => {
      loadStatuses();
      loadCategories();
    });

    const confirm = useConfirm();
    const toast = useToast();

    // Status
    const statuses = ref();

    const loadStatuses = () => {
      NicheAnalysisService.listStatuses().then(
        ({ data }) => (statuses.value = data.statuses)
      );
    };

    const addStatus = (name, note) => {
      const payload = {
        status: {
          name: name,
          note: note,
        },
      };

      NicheAnalysisService.createStatus(payload)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Status not created",
              life: 3000,
            });
          } else {
            toast.add({
              severity: "success",
              summary: "Status created",
              life: 3000,
            });
            loadStatuses();
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const deleteStatus = (index) => {
      let record = statuses.value[index];
      confirm.require({
        message: `Are you sure you want to delete?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          NicheAnalysisService.deleteStatus(record.id)
            .then(({ data }) => {
              if (data.error) {
                console.error(data.error.message);
                toast.add({
                  severity: "error",
                  summary: "Status not deleted",
                  life: 3000,
                });
              } else {
                toast.add({
                  severity: "success",
                  summary: "Status deleted",
                  life: 3000,
                });
                loadStatuses();
              }
            })
            .catch((e) => {
              console.error(e);
            });
        },
      });
    };

    const onStatusRowReorder = (record, previousRecord) => {
      const payload = {
        id: record.id,
        previous_id: previousRecord?.id,
      };

      NicheAnalysisService.resortStatus(payload)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "warn",
              summary: "Status not resorted",
              life: 3000,
            });
          } else {
            toast.add({
              severity: "success",
              summary: "Status resorted",
              life: 3000,
            });

            statuses.value = data.statuses;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const onStatusRowUpdate = (record, field) => {
      const payload = {};
      payload[field] = record[field];

      NicheAnalysisService.updateStatus(record.id, payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Status update",
            life: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "warn",
            summary: "Status not updated",
            life: 3000,
          });

          loadStatuses();
        });
    };

    // Category
    const categories = ref();

    const loadCategories = () => {
      NicheAnalysisService.listCategories().then(
        ({ data }) => (categories.value = data.categories)
      );
    };

    const addCategory = (name, note) => {
      const payload = {
        category: {
          name: name,
          note: note,
        },
      };

      NicheAnalysisService.createCategory(payload)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Category not created",
              life: 3000,
            });
          } else {
            toast.add({
              severity: "success",
              summary: "Category created",
              life: 3000,
            });
            loadCategories();
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const deleteCategory = (index) => {
      let record = categories.value[index];
      confirm.require({
        message: `Are you sure you want to delete?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          NicheAnalysisService.deleteCategory(record.id)
            .then(({ data }) => {
              if (data.error) {
                console.error(data.error.message);
                toast.add({
                  severity: "error",
                  summary: "Category not deleted",
                  life: 3000,
                });
              } else {
                toast.add({
                  severity: "success",
                  summary: "Category deleted",
                  life: 3000,
                });
                loadCategories();
              }
            })
            .catch((e) => {
              console.error(e);
            });
        },
      });
    };

    const onCategoryRowReorder = (record, previousRecord) => {
      const payload = {
        id: record.id,
        previous_id: previousRecord?.id,
      };

      NicheAnalysisService.resortCategory(payload)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "warn",
              summary: "Category not resorted",
              life: 3000,
            });
          } else {
            toast.add({
              severity: "success",
              summary: "Category resorted",
              life: 3000,
            });

            categories.value = data.categories;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const onCategoryRowUpdate = (record, field) => {
      const payload = {};
      payload[field] = record[field];

      NicheAnalysisService.updateCategory(record.id, payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Category update",
            life: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "warn",
            summary: "Category not updated",
            life: 3000,
          });

          loadCategories();
        });
    };

    return {
      statuses,
      addStatus,
      deleteStatus,
      onStatusRowReorder,
      onStatusRowUpdate,

      categories,
      addCategory,
      deleteCategory,
      onCategoryRowReorder,
      onCategoryRowUpdate,
    };
  },
});
</script>

<style scoped>
.addBrand {
  font-size: medium;
  font-weight: 500;
}
</style>
